import React from 'react';
import { Link } from "gatsby";

export interface PaginationProps {
	currentPage: number;
	numPages: number;
}

const Pagination: React.FunctionComponent<PaginationProps> = ({ currentPage, numPages }) => {
	const isFirst = currentPage === 1;
	const isLast = currentPage === numPages;
	const prevPage = currentPage - 1 === 1 ? '/articles' : (currentPage - 1).toString();
	const nextPage = `/articles/${(currentPage + 1).toString()}`;

	if (numPages === 1) {
		return <></>;
	}

	const style: React.CSSProperties = { margin: 5 };
	return (
		<nav>
			<div>
				{!isFirst && (
					<Link to={prevPage} rel="prev" style={style}>
						{/* << symbol */}
						{String.fromCharCode(171)}
					</Link>
				)}

				{Array.from({ length: numPages }, (_, i) => {
					if (i + 1 === currentPage) {
						return <span style={style} key={`pagination-number${i + 1}`}>{i + 1}</span>
					}
					else {
						return <Link style={style} key={`pagination-number${i + 1}`} className={i + 1 === currentPage ? 'active' : ''} to={`/articles/${i === 0 ? '' : i + 1}`}>
							{i + 1}
						</Link>
					}
				})}

				{!isLast && (
					<Link to={nextPage} rel="next" style={style}>
						{/* >> symbol */}
						{String.fromCharCode(187)}
					</Link>
				)}
			</div>
		</nav>
	);
};

export default Pagination;