import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Pagination from "../components/Pagination"
import Divider from "../components/Divider"
import MoreInfo from "../components/MoreInfo"

interface Props {
	pageContext: {
		currentPage: number;
		numPages: number;
	};
	data: {
		allMdx: { edges: PostEdge[] }
		site: {
			siteMetadata: {
				title: string
			}
		}
	}
}

const Articles = ({ data, pageContext }: Props) => {
	const posts = data.allMdx.edges

	return (
		<Layout>
			<SEO title="Articles" />
			<h1>Articles</h1>
			{posts.map(({ node }) => {
				const title = node.frontmatter.title || node.fields.slug
				return (
					<div key={node.fields.slug}>
						<h3 style={{ marginBottom: rhythm(1 / 4), }} >
							<Link to={node.fields.slug}>
								{title}
							</Link>
						</h3>
						<p
							dangerouslySetInnerHTML={{
								__html: node.frontmatter.description || node.excerpt,
							}}
						/>
					</div>
				)
			})}
			<Pagination currentPage={pageContext.currentPage} numPages={pageContext.numPages} />
			<Divider />
			<MoreInfo />
		</Layout>
	)
}

export default Articles

export const pageQuery = graphql`
  query articlesPageQuery($skip: Int!, $limit: Int!){
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
		sort: { fields: [frontmatter___date], order: DESC }
		filter: {fields: {draft: {ne: true}}, collection: { eq: "blog" }}
		limit: $limit,
      	skip: $skip
	) {
      edges {
        node {
          excerpt
          fields {
            slug
		  }
		  timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
			description
          }
        }
      }
    }
  }
`

interface PostEdge {
	node: {
		excerpt: string;
		fields: {
			slug: string
		};
		timeToRead: number;
		frontmatter: {
			date: string;
			title: string;
			description: string;
		}
	};
}